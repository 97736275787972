import TYPES from '@/types';
import Vue from 'vue';
import { currencyFormat } from '@/vue-app/utils/currency';
import { getGoalIconPath } from '@/vue-app/utils/goal-icon-path';

// Application
import GetInvestmentProductsQuery
  from '@/modules/flagship/catalogs/investment-products/application/queries/get-investment-products-query';
import GetSearchByCustomerQuery
  from '@/modules/flagship/investor-goal/search-by-customer/application/queries/get-search-by-customer-query';
import { SearchAllianzRecurringContributionsQuery } from '@/modules/my-investment/allianz/recurring-contributions/application/queries';

// Domain
import { SearchByCustomerDto }
  from '@/modules/flagship/investor-goal/search-by-customer/domain/dtos/search-by-customer-dto';
import {
  AllianzRecurringContributionsEntity,
} from '@/modules/my-investment/allianz/recurring-contributions/domain/entities/allianz-recurring-contributions-entity';
import {
  verifyIfInvestorGoalIsLinkedWithModerate,
} from '@/modules/flagship/customer-investment-product-fund-type/domain/services/verify-if-investor-goal-is-linked-with-an-investment-product';
import Inject from '@/modules/shared/domain/di/inject';
import { MessageNotifier } from '@/modules/shared/domain/notifiers/message_notifier';
import Translator from '@/modules/shared/domain/i18n/translator';
import { Values } from '@/modules/shared/domain/i18n/types';

type LinkedGoals = {
  id: string | null;
  label: string;
  icon: string;
  recurrent_assigned_amount: number;
  alt: string;
  is_moderate: boolean;
  icon_path: string;
}

export default class RecurrentContributionAmongLinkedGoalsDialogViewModel {
  @Inject(TYPES.GET_ALLIANZ_RECURRING_CONTRIBUTIONS_QUERY)
  private readonly get_allianz_recurring_contributions_query!:
    SearchAllianzRecurringContributionsQuery;

  @Inject(TYPES.GET_INVESTMENT_PRODUCTS_QUERY)
  private readonly get_investment_products_query!: GetInvestmentProductsQuery;

  @Inject(TYPES.GET_SEARCH_BY_CUSTOMER_QUERY)
  private readonly get_search_by_customer_query!: GetSearchByCustomerQuery;

  @Inject(TYPES.I18N)
  private readonly translator!: Translator;

  @Inject(TYPES.NOTIFIER)
  private readonly message_notifier!: MessageNotifier;

  private readonly customer_id = sessionStorage.getItem('user_id');

  readonly i18n_namespace = 'components.allianz-dashboard.recurrent_contribution_dialog';

  private readonly view: Vue;

  public constructor(view: Vue) {
    this.view = view;
  }

  private search_by_customer_dto: SearchByCustomerDto = {
    reload: true,
    associated_product_id: '',
    is_active: true,
  };

  linked_goals: Array<LinkedGoals> = [];

  recurring_contribution: AllianzRecurringContributionsEntity = {
    total_contribution: 0,
    contributions: [],
    home_desired: false,
  };

  monthly_collection_day = '';

  recurrent_contribution_amount = 0;

  show_recurring_contribution_dialog = false;

  window_number = 0;

  maximum_number_windows = 1;

  show_stepper = false;

  translate = (message: string, values?: Values) => this.translator.translate(`${this.i18n_namespace}.${message}`, values);

  initialize = async () => {
    await this.loadRecurrentContributionInformation();
    await this.loadInvestmentProducts();
    await this.loadActiveGoals();
    this.setRecurrentAssignedAmount();
  }

  get is_btn_x_large_size_active() {
    return (!this.view.$vuetify.breakpoint.smAndDown);
  }

  getFormattedAmount = (amount: number) => (currencyFormat(amount));

  getIconMaxWidth = (icon_goal_name: string) => ((icon_goal_name === 'icon-add.svg') ? '30px' : '21px');

  getCustomizedModerateClassForMainVCol = (is_moderate: boolean) => ((is_moderate) ? 'white--text primary' : 'mb-3');

  loadRecurrentContributionInformation = async () => {
    try {
      this.recurring_contribution = await this.get_allianz_recurring_contributions_query.execute();
      if (this.recurring_contribution.contributions.length > 1) {
        this.maximum_number_windows = this.recurring_contribution.contributions.length + 1;
        this.show_stepper = true;
      }
    } catch {
      this.message_notifier.showErrorNotification(this.translate('errors.get_allianz_account_query'));
    }
  }

  loadInvestmentProducts = async () => {
    try {
      const investment_products = await this.get_investment_products_query.execute();
      const product = investment_products.find((item) => item.name === 'sowos_wealth');
      this.search_by_customer_dto.associated_product_id = product!.id;
    } catch {
      this.message_notifier.showErrorNotification(this.translate('errors.get_investment_products_query'));
    }
  }

  loadActiveGoals = async () => {
    try {
      const active_goals = await this.get_search_by_customer_query
        .execute(this.search_by_customer_dto);
      let is_moderate = false;
      active_goals.forEach((goal) => {
        is_moderate = false;
        if (goal.investment_product_fund_types && goal.investment_product_fund_types.linked) {
          is_moderate = verifyIfInvestorGoalIsLinkedWithModerate(
            goal.investment_product_fund_types.linked,
          );
        }
        this.linked_goals.push({
          id: goal.investor_goal_id,
          // eslint-disable-next-line max-len
          label: (goal.custom_investor_goal) ? goal.custom_investor_goal.goal_name : goal.investment_goal_type.label,
          icon: (goal.custom_investor_goal) ? goal.custom_investor_goal.custom_goal_type.icon_name : 'icon-retirement.svg',
          recurrent_assigned_amount: 0,
          alt: (goal.custom_investor_goal) ? 'alts.custom' : 'alts.img_fund',
          is_moderate,
          icon_path: getGoalIconPath(
            goal.investor_goal_id,
            goal.custom_investor_goal?.custom_goal_type?.icon_name || 'icon-retirement.svg',
            goal.custom_investor_goal?.updated_at || '',
          ),
        });
      });
    } catch {
      this.message_notifier.showErrorNotification(this.translate('errors.get_search_by_customer_query'));
    }
  }

  setRecurrentAssignedAmount = (summary = false) => {
    const goalMap = new Map(this.linked_goals.map(
      (goal) => [goal.id, goal],
    ));

    const contributionsToProcess = (!summary && (
      this.window_number < this.recurring_contribution.contributions.length))
      ? [this.recurring_contribution.contributions[this.window_number]]
      : this.recurring_contribution.contributions;

    this.recurrent_contribution_amount = 0;

    this.linked_goals.forEach((goal, index) => {
      this.linked_goals[index].recurrent_assigned_amount = 0;
    });

    contributionsToProcess.forEach((contribution) => {
      if (summary) {
        const first_contribution = contributionsToProcess[0];
        const second_contribution = contributionsToProcess[1];
        this.monthly_collection_day = this.translate('days_to_pay', {
          first: first_contribution.collection_day, second: second_contribution.collection_day,
        });
      } else {
        this.monthly_collection_day = this.translate('day_to_pay', { day: contribution.collection_day! });
      }
      this.recurrent_contribution_amount += contribution.amount || 0;

      contribution.distributions.forEach(({ investor_goal_id, recurrent_assigned_amount }) => {
        if (investor_goal_id && goalMap.has(investor_goal_id)) {
          goalMap.get(investor_goal_id)!.recurrent_assigned_amount = (
            goalMap.get(investor_goal_id)!.recurrent_assigned_amount || 0
          ) + recurrent_assigned_amount;
        } else {
          this.setRecurrentUnassignedAmount(recurrent_assigned_amount);
        }
      });
    });
  }

  setRecurrentUnassignedAmount = (recurrent_assigned_amount: number) => {
    const unassigned_index = this.linked_goals.findIndex(
      (linked_goal) => linked_goal.id === null,
    );
    if (recurrent_assigned_amount > 0) {
      if (unassigned_index < 0) {
        this.linked_goals.push({
          id: null,
          label: this.translate('recurrent_unassigned_balance'),
          icon: 'noun-coins.svg',
          recurrent_assigned_amount,
          alt: 'alts.unassigned',
          is_moderate: false,
          icon_path: getGoalIconPath('', 'noun-coins.svg', ''),
        });
      } else {
        this.linked_goals[unassigned_index].recurrent_assigned_amount += recurrent_assigned_amount;
      }
    }
  }

  windowChange = () => {
    const summary = this.window_number === this.recurring_contribution.contributions.length;
    this.setRecurrentAssignedAmount(summary);
  }

  showRecurringDepositDialog = () => {
    this.view.$emit('showEditRecurringContribution');
  }
}
